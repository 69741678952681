$(function() {
  // We can attach the `fileselect` event to all file inputs on the page
    $(document).on('change', '.ufile', function() {
        var input = $(this),
            numFiles = input.get(0).files ? input.get(0).files.length : 1,
            label = input.val().replace(/\\/g, '/').replace(/.*\//, ''),
            typeId = $(this).data('type');
            //input.trigger('fileselect', [numFiles, label]);
        var input = $(".ufile-name-"+typeId),
            log = numFiles > 1 ? numFiles + ' files selected' : label;

        if( input.length ) {
            input.val(log);
        } else {
            if( log ) alert(log);
        }
    });

    // We can watch for our custom `fileselect` event like this
    $(document).ready( function() {
        $('.ufile').on('fileselect', function(event, numFiles, label) {

            var input = $(".ufile-name-"+typeId),
                log = numFiles > 1 ? numFiles + ' files selected' : label;

            if( input.length ) {
                input.val(log);
            } else {
                if( log ) alert(log);
            }

        });
        
        $("#search-docs").keyup(function () {
            var value = $(this).val().toLowerCase();
            $(".attachement-list").addClass('preload-container');
            //alert(value);

            $('.files-container').find('li').each(function(){
                //alert($(this).attr('id'));
                str = $(this).find(".attachment-file-name > a").text().toLowerCase();
                if(str.indexOf(value) == -1) {
                    $(this).addClass('none');
                } else {
                    $(this).removeClass('none');
                }
            });
            setTimeout(function(){ $(".attachement-list").removeClass('preload-container'); }, 300);
            
        });
		
		$("input.upload").change(function (e) {console.log(e.target.files);
			var $files = e.target.files;
			var $len = $files.length;

			for (var i = 0; i < $len; i++) {

				f = $files[i];

				var ext = f.name.split('.').pop().toLowerCase(); console.log(ext);
				/*if ($.inArray(ext, ['gif', 'png', 'jpg', 'jpeg']) == -1) {
					alert('invalid extension!');

				}*/
				/* var fileSize = ($(element)[0].files[0].size / 1024 / 1024); //size in MB
				if (fileSize > maxSize) {
					alert("Large file");// if Maxsize from Model > real file size alert this
				}*/
			}
			
			
			var fileName = '';
			if( e.target.files && e.target.files.length > 1 )
				fileName = ( $(this).attr( 'data-multiple-caption' ) || '' ).replace( '{count}', e.target.files.length );
			else
				fileName = $(this).val(); //e.target.value.split( '\\' ).pop(); 

			//$("#uploadFile").text(fileName);
			$("label[for='"+$(this).attr("id")+"']").text(fileName);
			//$("label[for='material-file-1']").text('use 10 kms'); console.log($("label[for='material-file-1']"));
			//$(this).next("label").text(fileName);
		});
    });
  
});


$(document).on("click", ".file-download", function (event) {
   
    var $downloadPath = $(this).attr("href");
    $( event.target ).closest( "li" ).find('.attachment-downloading').removeClass( "none" );
    $.ajax({
		type: "GET",
		url: $downloadPath,
		success: function(data, status) {
            //console.log($downloadPath);
            window.location.replace($downloadPath);
            $( event.target ).closest( "li" ).find('.attachment-downloading').addClass( "none" );
		},
        error: function(data) {
            $( event.target ).closest( "li" ).find('.attachment-downloading').addClass( "none" );
            $('.files-container').parent().prepend('<div class="file-alert alert alert-danger">'+data.responseText+'</div>');
            $('.file-alert').delay(2000).fadeOut(600, function(){
                $(this).remove();
            });
        }
	});
    
    return false;
});

$(document).on("click", ".btn-export", function (event) {
    event.stopImmediatePropagation();
    event.preventDefault();
    var $exportPath = $(this).attr("href"); //console.log($("#form-data-search").serialize());
   // console.log($exportPath+'?'+$("#form-data-search").serialize());
    if( $(this).data("form") )
        window.location.replace($exportPath+'?'+$($(this).data("form")).serialize());
    else
        window.location.replace($exportPath+'?'+$("#form-data-search").serialize());
   /* $.ajax({
		type: "GET",
        data: $("#form-data-search").serialize(),
		url: $exportPath,
        //dataType: 'binary',
        //headers:{'Content-Type':'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','X-Requested-With':'XMLHttpRequest'},
		success: function(data, status) {
            console.log($exportPath+'?'+$("#form-data-search").serialize());
            window.location.replace($exportPath+'?'+$("#form-data-search").serialize());
		},
        error: function(data) {
            $('.toolbar-data').prepend('<div class="export-alert alert alert-danger">Problem z przesłaniem danych</div>');
            $('.export-alert').delay(2000).fadeOut(600, function(){
                $(this).remove();
            });
        }
	});*/
    
    return false;
});

$(document).on("submit", ".modalAjaxFormExport", function (event) {
    event.stopImmediatePropagation();
    event.preventDefault();
    var $exportPath = $(this).attr("action");
    var $target = $($(this).attr("data-target"));
    $target.modal("hide");
    window.location.replace($exportPath+'?'+$(this).serialize());
   /* $.ajax({
		type: "GET",
        data: $("#form-data-search").serialize(),
		url: $exportPath,
        //dataType: 'binary',
        //headers:{'Content-Type':'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','X-Requested-With':'XMLHttpRequest'},
		success: function(data, status) {
            console.log($exportPath+'?'+$("#form-data-search").serialize());
            window.location.replace($exportPath+'?'+$("#form-data-search").serialize());
		},
        error: function(data) {
            $('.toolbar-data').prepend('<div class="export-alert alert alert-danger">Problem z przesłaniem danych</div>');
            $('.export-alert').delay(2000).fadeOut(600, function(){
                $(this).remove();
            });
        }
	});*/
    
    return false;
});

$(document).on("click", ".file-delete", function (event) {
    event.stopImmediatePropagation();
    event.preventDefault();
    var $list = $('.files-container');//console.log($table);
    var $fileId = $(this).data('id');
    $.ajax({
        url: $(this).attr("href"),
        method: 'POST',
        success: function(data, status) {
            if(data.result) {
                $('li#file-'+$fileId).prepend('<div class="file-alert alert alert-success">Dokument został usunięty</div>').delay(1000).fadeOut(600, function(){  $(this).remove(); });
            } else {
                $('li#file-'+$fileId).prepend('<div class="file-alert alert alert-danger">'+data.error+'<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span> </button>'+'</div>');
            }
        }
    });
    return false;
});


$(document).on("submit", "#file-form", function (event) {
    event.stopImmediatePropagation();
    event.preventDefault();
    /*var $list = $('.files-container');//console.log($table);
    var $fileId = $(this).data('id');
    $.ajax({
        url: $(this).attr("action"),
        method: 'POST',
        success: function(data, status) {
            if(data.success) {
                $('li#file-'+$fileId).prepend('<div class="file-alert alert alert-success">Dokument został zaktualizowany</div>').delay(1000).fadeOut(600, function(){ console.log($('.files-container').find('li#file-'+data.id+' .file-download'));  $('.files-container').find('li#file-'+data.id+' .file-download').text(data.new_title); });
            } else {
                $('li#file-'+$fileId).prepend('<div class="file-alert alert alert-danger">'+data.error+'</div>').delay(1000).fadeOut(600, function(){  console.log('error'); });
            }
        }
    });
    */
    var $form = $(this); //$($(this).attr('data-form'));
	var $target = $($form.attr("data-target"));
	var $table = $($(this).attr('data-table'));

	$.ajax({
		type: $form.attr("method"),
		url: $form.attr("action"),
		data: $form.serialize(),

		success: function(data, status) {
            if(data.success) {
				$('li#file-'+data.id).prepend('<div class="file-alert alert alert-success">Dokument został zaktualizowany</div>');
                setTimeout(function(){
                        $('li#file-'+data.id).find('.attachment-file-name .file-download').text(data.new_title);
                        $('li#file-'+data.id).find('.attachment-file-category span').text(data.new_category);
                        $('li#file-'+data.id).find('.file-alert').remove();
                    }, 1000);
				$target.modal("hide");
			} else { 
				$target.find(".modalContent").html(data.html);
                
                 var arr = data.errors; var txtError = '';
                $.each(arr, function(index, value)
                {
                    if (value.length != 0)
                    {
                       // $("#validation-form").removeClass('none').removeClass('alert-success').addClass('alert-danger').append('<strong>'+ value +'</strong><br/>');
                        txtError += '<strong>'+ value +'</strong><br/>';
                    }
                });
               // $("#validation-form").show();
                txtError += '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span> </button>';
                $target.find(".modal-body").prepend('<div class="alert alert-danger alert-dismissible"  style="margin-bottom:5px;">'+ txtError +'</div>');
                
			}
		}
    });
    
    return false;
});

function OnProgress(event, position, total, percentComplete){    
    //Progress bar
    console.log('total: '+total);
    //$('#pb').width(percentComplete + '%') //update progressbar percent complete
    //$('#pt').html(percentComplete + '%'); //update status text
    $("#progress-wrp .progress-bar").css("width", + percentComplete +"%");
    $("#progress-wrp .status").text(percentComplete +"%");
}

function updateProgress(evt){
  if (evt.lengthComputable){
     var percentComplete = (evt.loaded / evt.total)*100;  
      console.log(percentComplete+"% completed");
   } 
}

var request = new XMLHttpRequest(); 
request.onprogress=updateProgress;

$(document).on("click", ".btn-file-send", function (event) {
    var $form = $(this);
	var $table = $($form.data("table"));
	var $label = $form.data("label");
	var $inputFile = $form.attr("data-inputfile");
    var $typeId = $(this).data("type");
    
    $(".upload-"+$typeId+" .progress-wrp").removeClass("none");
    
    var csrfToken = $('meta[name="csrf-token"]').attr("content");

    //var formData = new FormData($(this)[0]); //console.log(formData);
    var formData = new FormData();
    //formData.append( 'Files[files][]', $(".ufile")[0].files[0] );
    $.each( $(".upload-"+$typeId+" .ufile")[0].files, function(i, file) {
        //ajaxData.append('photo['+i+']', file);
        formData.append( 'Files[files]['+i+']', file );
     } );
    formData.append( 'Files[title_file]', $(".ufile-name-"+$typeId).val());
    formData.append( 'Files[id_dict_type_file_fk]', $(".ufile-type-"+$typeId).val());
    formData.append( 'Files[id_type_file_fk]', $(this).data("type"));
    formData.append( 'Files[id_fk]', $(this).data("id"));
    //formData.append( '_csrf', csrfToken );
        //alert($form.data("action"));
    $.ajax({
        type: "POST",
		url: $form.data("action"),
        data:  formData,
        async: false,
        xhr: function () {
			//console.log('xhr');
            var xhr = new window.XMLHttpRequest();
            xhr.upload.addEventListener("progress", function (evt) {
                if (evt.lengthComputable) {
                    var percentComplete = evt.loaded / evt.total;
                    console.log(percentComplete);
                    $('.progress').css({
                        width: percentComplete * 100 + '%'
                    });
                    if (percentComplete === 1) {
                        $('.progress').addClass('hide');
                    }
                }
            }, false);
            xhr.addEventListener("progress", function (evt) {
                if (evt.lengthComputable) {
                    var percentComplete = evt.loaded / evt.total;
                    console.log(percentComplete);
                    $('.progress').css({
                        width: percentComplete * 100 + '%'
                    });
                }
            }, false);
            return xhr;
        },
        //uploadProgress:OnProgress, 
        success: function (data) {
			$(".progress-wrp").addClass("none");
            if(data.result) {
				$form.closest('.upload-container').find('input[type=file], input.ufile-name').val(''); 
               // $(".ufile")[0].reset(); //reset form
				//console.log('$inputFile: '+$inputFile+', $label: '+$label);
				$("label[for='"+$inputFile+"']").text($label);
				//$table.bootstrapTable('refresh');
                $(".files-container-"+$typeId).prepend(data.new);
                
				$('.file-upload-alert-'+$typeId).prepend("<div class='alert alert-success message-remove'>"+data.success+"</div>");
                setTimeout(function() {
                  $('.message-remove').remove();
                }, 2000);
                
                //submit_btn.val("Upload").prop( "disabled", false); //enable submit button once ajax is done

			} else {
				$('.file-upload-alert-'+$typeId).prepend("<div class='alert alert-success message-remove'>"+data.error+"</div>");
                setTimeout(function() {
                  $('.message-remove').remove();
                }, 2000);
			}
        },
        cache: false,
        contentType: false,
        processData: false
    });
    event.stopImmediatePropagation();
	event.preventDefault();
   // return false;
});


$(document).on("submit", "form.form-upload-file", function (event) {
    var $form = $(this);
	var $target = $($form.attr("data-target"));
	var $table = $($form.attr("data-table"));
	var $label = $form.attr("data-label");
	var $inputFile = $form.attr("data-inputfile");

    var formData = new FormData($(this)[0]); //console.log(formData);

    $.ajax({
        type: $form.attr("method"),
		url: $form.attr("action"),
        data:  formData,
        async: false,
        success: function (data) {
			if(data.result) {
				$form.closest('form').find('input[type=file], input[type=text]').val(''); 
				//console.log('$inputFile: '+$inputFile+', $label: '+$label);
				$("label[for='"+$inputFile+"']").text($label);
				$table.bootstrapTable('refresh');
				if ($('#'+$form.attr('id')+' div.alert').length){
					$('#'+$form.attr('id')+' div.alert').removeClass('alert-danger').addClass('alert-success').text(data.success);
				} else {
					$form.prepend('<div class="alert alert-success" role="alert">'+data.success+'</div>');
				}
			} else {
				if ($('#'+$form.attr('id')+' div.alert').length){
					$('#'+$form.attr('id')+' div.alert').removeClass('alert-success').addClass('alert-danger').text(data.error);
				} else {
					$form.prepend('<div class="alert alert-danger" role="alert">'+data.error+'</div>');
				}
			}
			KAPI._fix();
			KAPI.fix_sidebar();
        },
        cache: false,
        contentType: false,
        processData: false
    });
    event.stopImmediatePropagation();
	event.preventDefault();
   // return false;
});