/*jslint browser: true */
/*global window */
/*jshint unused:false*/

'use strict';

var KAPI = {
    utils: {},
    burger: {},
	shoppingBag: {},
    scrollYPosition: 0
};

var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

KAPI.utils = {

    _screenSize () {
        let w = window,
            d = document,
            e = d.documentElement,
            g = d.getElementsByTagName('body')[0],
            x = w.innerWidth|| e.clientWidth|| g.clientWidth,
            y = w.innerHeight|| e.clientHeight|| g.clientHeight,
            sizes = {x: x, y: y};
        return sizes;
    },

    openSidebar () {
        if ( document.querySelector('.js-open-nav') ) {
            let burgerButton = document.querySelector('.js-open-nav'),
                overlay = document.querySelector('.overlay'),
                sidebar = document.querySelector('.js-sidebar');

            burgerButton.addEventListener("click", (event) => {
                event.preventDefault();
                overlay.classList.add('overlay--show');
                sidebar.classList.add('l-sidebar--open');
                return false;
            });
        }
    },

    closeSidebar () {
        if ( document.querySelector('.js-close-nav') ) {
            let burgerCloseButton = document.querySelector('.js-close-nav');

            burgerCloseButton.addEventListener("click", (event) => {
                event.preventDefault();
                KAPI.utils.__closeSidebar();
                return false;
            });
        }
    },

    // closePopup () {
    //     if ( document.querySelector('.js-close-popup') ) {
    //         let popupCloseButton = document.querySelector('.js-close-popup');

    //         popupCloseButton.addEventListener("click", (event) => {
    //             event.preventDefault();
    //             document.querySelector('.js-popup').classList.remove('popup-wrapper--show');
    //             return false;
    //         });
    //     }
    // },

    closePopup (obj, sessionKey) {
        let popupWrapper = obj.parentElement.parentElement;

        popupWrapper.classList.remove('popup-wrapper--show');
        popupWrapper.classList.add('popup-wrapper--was-shown');

        if ( sessionKey ===  'popupPromoCode') {
            sessionKey = sessionKey + '-' + document.querySelector('.js-popup-promocode').getAttribute('data-id');
        }

        if (sessionKey) {
            sessionStorage.setItem(sessionKey, 1);
        }

        // open popup with promocode if exists and wasnt shown already
        if ( document.querySelector('.js-popup-promocode:not(.popup-wrapper--was-shown)') ) {
            // if ( KAPI.cookie.showCookie('promoCodeConfirmed') !== document.querySelector('.js-popup-promocode').getAttribute('data-id') ) {
            //     if ( !sessionStorage.getItem('popupPromoCode') ) {
            //         setTimeout(function(){
            //             document.querySelector('.js-popup-promocode').classList.add('popup-wrapper--show');
            //         }, 2000);
            //     }
            // }

            if ( !KAPI.cookie.showCookie('promoCodeConfirmed-' + document.querySelector('.js-popup-promocode').getAttribute('data-id')) ) {
                if ( !sessionStorage.getItem('popupPromoCode-' + document.querySelector('.js-popup-promocode').getAttribute('data-id')) ) {
                    setTimeout(function(){
                        document.querySelector('.js-popup-promocode').classList.add('popup-wrapper--show');
                    }, 2000);
                }
            }
        }

        return false;

        // if ( document.querySelector('.js-close-popup') ) {
        //     let popupCloseButton = document.querySelector('.js-close-popup');

        //     popupCloseButton.addEventListener("click", (event) => {
        //         event.preventDefault();
        //         document.querySelector('.js-popup').classList.remove('popup-wrapper--show');
        //         return false;
        //     });
        // }
    },

    closeSidebarByHitEsc () {
        document.addEventListener('keyup', function( event ) {
            if (event.keyCode === 27) {
                KAPI.utils.__closeSidebar();
            }
        });
    },

    closeSidebarByClickOnOverlay () {
        if ( document.querySelector('.overlay') ) {
            let overlay = document.querySelector('.overlay');

            overlay.addEventListener("click", (event) => {
                event.preventDefault();
                KAPI.utils.__closeSidebar();
                return false;
            });
        }
    },

    __closeSidebar () {
        let overlay = document.querySelector('.overlay'),
            sidebar = document.querySelector('.js-sidebar');
        overlay.classList.remove('overlay--show');
        sidebar.classList.remove('l-sidebar--open');
    },

    showMore () {
        if ( document.querySelector('.js-show-more') ) {
            var showMoreButtons = document.querySelectorAll('.js-show-more');

            [].forEach.call(showMoreButtons, function(showMoreButton) {
                showMoreButton.addEventListener("click", function( event ) {
                    event.preventDefault();
                    let targetId = event.currentTarget.getAttribute('data-target-id');
                    document.querySelector('#' + targetId).style.display = 'block';
                    this.parentNode.removeChild(this);
                }, false);
            });
        }
    },

    showDeliveryOptions () {
        if ( document.querySelector('.js-delivery-options') ) {
            var deliveryOptionsButtons = document.querySelectorAll('.js-delivery-options');

            [].forEach.call(deliveryOptionsButtons, function(deliveryOptionsButton) {
                deliveryOptionsButton.addEventListener("click", function( event ) {
                    event.preventDefault();
                    let popup;
                    if (this.nextElementSibling.classList.contains('js-delivery-popup')) {
                        popup = this.nextElementSibling;

                        popup.classList.contains('product-delivery__popup--open') ? popup.classList.remove('product-delivery__popup--open') : popup.classList.add('product-delivery__popup--open');

                    }

                }, false);
            });
        }
    },

    closeDeliveryOptions () {
        if ( document.querySelector('.js-delivery-options-close') ) {
            var deliveryOptionsCloseButtons = document.querySelectorAll('.js-delivery-options-close');

            [].forEach.call(deliveryOptionsCloseButtons, function(deliveryOptionsCloseButton) {
                deliveryOptionsCloseButton.addEventListener("click", function( event ) {
                    event.preventDefault();
                    this.parentElement.classList.remove('product-delivery__popup--open');
                }, false);
            });
        }
    },

    showAsidePage () {
        if ( document.querySelector('.js-aside-page') ) {
            var showAsidePageButtons = document.querySelectorAll('.js-aside-page');

            [].forEach.call(showAsidePageButtons, function(showAsidePageButton) {
                showAsidePageButton.addEventListener("click", function( event ) {
                    event.preventDefault();

                    document.body.classList.contains('move-left') ? document.body.classList.remove('move-left') : document.body.classList.add('move-left');

                }, false);
            });
        }
    },

    showAsidePageMobile () {
        if ( document.querySelector('.js-aside-page-mobile') ) {
            var showAsidePageButtons = document.querySelectorAll('.js-aside-page-mobile');

            [].forEach.call(showAsidePageButtons, function(showAsidePageButton) {
                showAsidePageButton.addEventListener("click", function( event ) {
                    event.preventDefault();
                    let asidePage = document.querySelector('.aside-page');
                    asidePage.classList.contains('aside-page--show') ? asidePage.classList.remove('aside-page--show') : asidePage.classList.add('aside-page--show');

                }, false);
            });
        }
    },

    giftWrapPopup () {
        if ( document.querySelector('.js-tooltip') ) {
            var giftWrapPopupButtons = document.querySelectorAll('.js-tooltip');

            [].forEach.call(giftWrapPopupButtons, function(giftWrapPopupButton) {
                giftWrapPopupButton.addEventListener("click", function( event ) {
                    event.preventDefault();

                    let popup = this.parentElement.parentElement.querySelector('.shopping-bag__gift-wrap-popup'),
                        popupShowClassName = 'shopping-bag__gift-wrap-popup--show';

                    popup.classList.contains(popupShowClassName) ? popup.classList.remove(popupShowClassName) : popup.classList.add(popupShowClassName);

                }, false);
            });
        }
    },

    sharePopup () {
        if ( document.querySelector('.js-share-popup') ) {
            var sharePopupButtons = document.querySelectorAll('.js-share-popup');

            [].forEach.call(sharePopupButtons, function(sharePopupButton) {
                sharePopupButton.addEventListener("click", function( event ) {
                    event.preventDefault();

                    let popup = sharePopupButton.parentElement,
                        popupShowClassName = 'share--open';

                    popup.classList.remove('share--off');

                    popup.classList.contains(popupShowClassName) ? popup.classList.remove(popupShowClassName) : popup.classList.add(popupShowClassName);

                }, false);
            });
        }
    },

    magnific () {

      $('a.js-popup, a.popup, a.js-video').on('click', function() {
          var href = $(this).attr('href'),
              type = href.substring(href.length-4, href.length),
              title = $(this).attr('title');

          if(type === '.jpg' || type === '.gif' || type === '.png' || type === '.jpeg')
          {
            type = 'image';
          }
          else {
            type = 'iframe';
          }

          $.magnificPopup.open({
            items: {
              src: href
            },
            type: type,

            image: {
              markup: '<div class="mfp-figure">'+
                        '<div class="mfp-img"></div>'+
                        '<div class="mfp-bottom-bar">'+
                          '<div class="mfp-title"></div>'+
                          '<div class="mfp-counter"></div>'+
                          '<p class="mfp-close"></p>'+
                        '</div>'+
                        '<p class="mfp-close"></p>'+
                      '</div>',

              cursor: 'mfp-zoom-out-cur',
              titleSrc: title,

              verticalFit: true, // Fits image in area vertically

              tError: '<a href="%url%">The image</a> could not be loaded.' // Error message
            },

            iframe: {
              patterns: {
                youtube: {
                  index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).
                  id: 'v=', // String that splits URL in a two parts, second part should be %id%
                  src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0' // URL that will be set as a source for iframe.
                }
              }
            },
            //closeMarkup: '<a class="mfp-close btn btn--gold">CLOSE</a>',

            mainClass: 'mfp-fade',

            titleSrc: title,
            gallery: {
              enabled: true,
              navigateByImgClick: true
            }
          });

          return false;
      });
    },

    ajaxPopup () {
        $('.js-ajax-popup').on('click', function (e) {
            var self = this;

            $.magnificPopup.open({
                type: 'ajax',
                items: {
                    src: self.getAttribute('data-href')
                }
            }, 0);

            return false;
        });
    },

    scrollToID (id, context = 'html,body') {
        var path = window.location.pathname;
        var offSet = 80;
        var x = KAPI.utils._screenSize().x;

        if (x < 768) {
            offSet = 60;
        }
        var targetOffset = $(id).offset().top - offSet;

        $(context).animate({scrollTop:targetOffset}, 1000);
    },

    hashAnchorClick () {
        $('a[href*="#"]:not(.js-scroll-to)').on('click', function (e) {

            var target = this.hash,
                hashValue = target.substr(target.indexOf("#"));

            if (hashValue.length) {
                KAPI.utils.scrollToID( hashValue );
            }

            return false;
        });
    },

    fixedContentAnchorClick () {
        $('a.js-scroll-to[href*="#"]').on('click', function (e) {

            var target = this.hash,
                hashValue = target.substr(target.indexOf("#"));

            if (hashValue.length) {
                KAPI.utils.scrollToID( hashValue, '.js-scroll-to-wrapper' );
            }

            return false;
        });
    },

    openPopupById: function(settings) {
        if (!settings.id) {
            return;
        }

        let shippingPopup = document.querySelector('#' + settings.id);

        if (shippingPopup) {
            shippingPopup.classList.add('popup-wrapper--show');
        }
    },

    openPopupOnClick () {
        if ( document.querySelector('.js-open-popup') ) {
            var popupButtons = document.querySelectorAll('.js-open-popup');

            [].forEach.call(popupButtons, function(popupButton) {
                popupButton.addEventListener("click", function( event ) {
                    event.preventDefault();

                    if (event.currentTarget.href) {
                        window.open(
                            event.currentTarget.href,
                            '_blank',
                            'toolbar=no, scrollbars=yes, resizable=yes, width=500, height=400'
                        );
                    }

                }, false);
            });
        }
    },

    infiniteScroll () {
        var infinite;

        if ($('#js-infinity-wrapper').length) {

            infinite = new Waypoint.Infinite({
                element: $('#js-infinity-wrapper')[0],
                context: document.querySelector('.l-main')
            });
        }
    },

    cardNumberValidation () {
        if ( document.querySelector('#card-number') ) {
            let numberCardInput = document.querySelector('#card-number');

            numberCardInput.addEventListener("input", (event) => {
                event.preventDefault();

                var target = event.target, position = target.selectionEnd, length = target.value.length;

                target.value = target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
                target.selectionEnd = position += ((target.value.charAt(position - 1) === ' ' && target.value.charAt(length - 1) === ' ' && length !== target.value.length) ? 1 : 0);

                target.value = target.value.substr(0, 19);
            });
        }
    },

    homepageAnim () {
        if ( document.querySelector('.l-header__product-img') ) {
            let products = document.querySelectorAll('.l-header__product-img'),
                timeoutAnimIn = 500,
                timeoutShow = 1750;

            [].forEach.call( products, function(product) {
                setTimeout(function(){
                    product.classList.add('anim-in');
                    product.parentElement.querySelector('.l-header__product-img-shadow').classList.add('anim-in');
                }, timeoutAnimIn);

                timeoutAnimIn = timeoutAnimIn + 300;

                setTimeout(function(){
                    product.classList.add('show');
                    product.classList.remove('anim-in');

                    product.parentElement.querySelector('.l-header__product-img-shadow').classList.add('show');
                    product.parentElement.querySelector('.l-header__product-img-shadow').classList.remove('anim-in');
                }, timeoutShow);

                timeoutShow = timeoutShow + 300;
            });
        }
    },

    hoverTimstampStart: 0,

    counter: 0,
    myInterval: null,
    interval: 30,

    hoverAnimation() {

        $(".js-homepage-anim").hover(function(e){
            let self = this;
            $(self).addClass('animate');

            KAPI.utils.counter = 0;
            KAPI.utils.myInterval = setInterval(function () {
                ++KAPI.utils.counter;
            }, KAPI.utils.interval);
        },function(e){
            let self = this,
                animDuration = self.getAttribute('data-anim-duration')*1,
                different = (parseFloat(KAPI.utils.counter * KAPI.utils.interval)/1000) % parseFloat((animDuration/1000) );

            clearInterval(KAPI.utils.myInterval);
            KAPI.utils.counter = 0;

            setTimeout(function() {
                $(self).removeClass('animate');
            }, (animDuration - (different*1000) ) );
            
        });
    },

    init () {
        this.openSidebar();
        // this.closePopup();
        this.closeSidebar();
        this.closeSidebarByHitEsc();
        this.closeSidebarByClickOnOverlay();
        this.showMore();
        this.showDeliveryOptions();
        this.closeDeliveryOptions();
        this.showAsidePage();
        this.showAsidePageMobile();
        this.giftWrapPopup();
        this.magnific();
        this.ajaxPopup();
        this.sharePopup();
        this.hashAnchorClick();
        this.infiniteScroll();
        this.openPopupOnClick();
        this.fixedContentAnchorClick();

        this.cardNumberValidation();
        this.homepageAnim();
        this.hoverAnimation();
    }
};

KAPI.burger = {
    showHideNav: function showHideNav() {
        var burgerButton = document.querySelector('.js-burger'),
            asideNav = document.querySelector('.js-nav'),
            mainContent = document.querySelector('.js-main');

        burgerButton.addEventListener('click', function (event) {
            event.preventDefault();
            if (asideNav.classList.contains('l-navbar--hide')) {
                this.classList.add('is-active');
                asideNav.classList.remove('l-navbar--hide');
                mainContent.classList.remove('l-main--full');
            } else {
                asideNav.classList.add('l-navbar--hide');
                mainContent.classList.add('l-main--full');
                this.classList.remove('is-active');
            }
        });
    },

    showNavSubmenu: function showNavSubmenu() {
        var submenuButtons = document.querySelectorAll('.js-submenu');

        [].forEach.call(submenuButtons, function (submenuButton) {
            submenuButton.addEventListener('click', function (event) {
                event.preventDefault();
                var nextSibling = this.nextSibling;

                while (nextSibling && nextSibling.nodeType !== 1) {
                    nextSibling = nextSibling.nextSibling;
                }

                if (this.classList.contains('open')) {
                    this.classList.remove('open');
                    nextSibling.classList.add('l-navbar__sub-menu--close');
                } else {
                    this.classList.add('open');
                    nextSibling.classList.remove('l-navbar__sub-menu--close');
                }
            });
        });
    },

    openCloseBurgerMenu: function openCloseBurgerMenu() {
        if (document.querySelector('.js-burger')) {
            var burgerButton = document.querySelector('.js-burger'),
                navbar = document.querySelector('.l-navbar'),
                BURGER_OPEN_CLASS_NAME = 'burger--open',
                NAVBAR_BURGER_OPEN_CLASS_NAME = 'l-navbar--burger-open';

            burgerButton.addEventListener("click", function (event) {
                event.preventDefault();

                if (burgerButton.classList.contains(BURGER_OPEN_CLASS_NAME)) {
                    burgerButton.classList.remove(BURGER_OPEN_CLASS_NAME);
                    navbar.classList.remove(NAVBAR_BURGER_OPEN_CLASS_NAME);
                } else {
                    burgerButton.classList.add(BURGER_OPEN_CLASS_NAME);
                    navbar.classList.add(NAVBAR_BURGER_OPEN_CLASS_NAME);
                }
            }, false);
        }
    },

    init: function init() {
        //this.showHideNav();
        //this.showNavSubmenu();
        this.openCloseBurgerMenu();
    }

};

KAPI.shoppingBag = {
    clickEvent () {
        if ( document.querySelector('.js-shopping-bag-button') ) {
            let shoppingBagButton = document.querySelector('.js-shopping-bag-button'),
                shoppingBag = document.querySelector('.js-shopping-bag');

            shoppingBagButton.addEventListener("click", (event) => {
                event.preventDefault();

                KAPI.shoppingBag.toggleShoppingBag()
                return false;
            });
        }
    },

    closeShoppingBag () {
        if ( document.querySelector('.js-close-shopping-bag') ) {
            let shoppingBagButton = document.querySelector('.js-close-shopping-bag'),
                shoppingBag = document.querySelector('.js-shopping-bag');

            shoppingBagButton.addEventListener("click", (event) => {
                event.preventDefault();
                shoppingBag.classList.remove('shopping-bag--show');
                return false;
            });
        }
    },

    toggleShoppingBag () {
        if ( document.querySelector('.js-shopping-bag') ) {
            let shoppingBag = document.querySelector('.js-shopping-bag');

            if (shoppingBag.classList.contains('shopping-bag--show')) {
                shoppingBag.classList.remove('shopping-bag--show');
            } else {
                shoppingBag.classList.add('shopping-bag--show');
            }
        }
    },

    __closeOnClickNotOnShoppingBag (event) {
        var current = event.target,
            parent = current.parentNode,
            isShoppingBagChild = false,
            x = KAPI.utils._screenSize().x;

        while ( parent.tagName !== 'HTML') {
            if(current.classList) {
                if (current.classList.contains('shopping-bag')) {
                    isShoppingBagChild = true;
                    break;
                } else {
                    current = current.parentNode;
                    parent = parent.parentNode;
                }
            }
        }

        isShoppingBagChild ? false : document.querySelector('.js-shopping-bag').classList.remove('shopping-bag--show');
    },

    closeShoppingBagByClick () {
        if (document.querySelector('.js-shopping-bag')) {
            document.addEventListener('click', KAPI.shoppingBag.__closeOnClickNotOnShoppingBag, false);
        }
    },

    init () {
        this.clickEvent();
        this.closeShoppingBag();
        this.closeShoppingBagByClick();
    }
};


KAPI.contact = {
    loadScript: function() {
        //$('#map').height(BRITANNIC.utils._screenSize().y);
        var script = document.createElement('script');
        script.type = 'text/javascript';
        //old key: AIzaSyC9H0sjKlOQGCPU6wMeSLn8axJHJWFhEdQ
        script.src = 'https://maps.googleapis.com/maps/api/js?v=3&key=AIzaSyBnTa3dLGKB-Qc7isrqN2O4xoqjw5-SuL0&' +
                'callback=KAPI.contact.map';
        document.body.appendChild(script);
    },

    map: function() {
        function init() {
            var draggable,
                myLatLngX,
                myLatLngY,
                mapZoom,
                mapId,
                isMultiple = false,
                isMobile = function() {
                    try { 
                        document.createEvent("TouchEvent"); return true; 
                    } catch(e) {
                        return false;
                    }
                };

            if(isMobile()) {
                draggable = false;
            } else {
                draggable = true;
            }

            var maps = document.querySelectorAll('.js-map');

            [].forEach.call(maps, function(mapObj) {

                myLatLngX = mapObj.getAttribute('data-latitude');
                myLatLngY = mapObj.getAttribute('data-longitude');

                if (mapObj.getAttribute('data-multiple')) {
                    isMultiple = mapObj.getAttribute('data-multiple') === 'true' ;

                    myLatLngX = 51.068631;
                    myLatLngY = 19.444755;
                }

                mapId = isMultiple ? mapObj.getAttribute('id') : false;
                mapZoom = isMultiple ? 8 : 17;

                var mapOptions = {
                        zoom: mapZoom,
                        center: new google.maps.LatLng(myLatLngX,myLatLngY),
                        scrollwheel: false,
                        draggable: draggable,

                        zoomControl: true,
                        zoomControlOptions: {
                            style: google.maps.ZoomControlStyle.LARGE,
                            position: google.maps.ControlPosition.RIGHT_TOP
                        },
                        styles:[{"featureType":"all","elementType":"labels.text.fill","stylers":[{"saturation":"-20"},{"color":"#a28bb5"},{"lightness":"50"}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#a28bb5"},{"lightness":"-20"},{"saturation":"20"}]},{"featureType":"administrative","elementType":"geometry","stylers":[{"color":"#a28bb5"},{"saturation":"15"}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#a28bb5"},{"lightness":"10"}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#a28bb5"},{"lightness":"-40"},{"weight":1.2}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#a28bb5"},{"lightness":"-20"},{"saturation":"20"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#a28bb5"},{"lightness":"-25"},{"saturation":"20"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#a28bb5"},{"lightness":"-35"},{"saturation":"20"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#000000"},{"lightness":29},{"weight":.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#a28bb5"},{"lightness":"-35"},{"saturation":"20"}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#a28bb5"},{"lightness":"-35"},{"saturation":"20"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#a28bb5"},{"lightness":"-10"},{"saturation":"20"}]}]
                };

                var marker, i;

                var mapElement = mapObj;
                var map = new google.maps.Map(mapElement, mapOptions);
                var image = '/images/marker.png';

                if (isMultiple) {
                    var infowindow = new google.maps.InfoWindow();
                    
                    for (i = 0; i < locations[mapId].length; i++) {  
                        marker = new google.maps.Marker({
                        position: new google.maps.LatLng(locations[mapId][i][1], locations[mapId][i][2]),
                        map: map,
                        icon: image,
                        animation: google.maps.Animation.DROP
                        });
                
                        google.maps.event.addListener(marker, 'click', (function(marker, i) {
                        return function() {
                            infowindow.setContent(locations[mapId][i][0]);
                            infowindow.open(map, marker);
                        }
                        })(marker, i));
                    }

                } else {
                    marker = new google.maps.Marker({
                        position: map.getCenter(),
                        icon: image,
                        map: map,
                        animation: google.maps.Animation.DROP,
                        url: 'http://maps.google.com/?q='+myLatLngX+','+myLatLngY
                    });
                }

                google.maps.event.addDomListener(window, 'resize', function() {
                    var center = map.getCenter();
                    google.maps.event.trigger(map, 'resize');
                    map.setCenter(center);
                });
            });

        }

        init();
    }
};

KAPI.burger.init();
KAPI.utils.init();
KAPI.shoppingBag.init();

if ($('.js-map').length) {
    KAPI.contact.loadScript();
}

/* ***************** */
/* nav scroll switch */
/* ***************** */

KAPI.scrollYPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

window.addEventListener('scroll', function () {
    //KAPI.utils.navbarPosition();
}, false);


$(document).ready(function () {
	/* $('.collapse').collapse();*/
	if( $("#playlist").length > 0 ) {
		$("#playlist li").on("click", function() {
			$("#playlist li").removeClass("active");
            $(this).addClass("active");
            if($(this).attr("movietype") == "youtube") {
                $("#embedarea").attr({
                    "src": $(this).attr("movieurl")
                });
                $("#embedarea").removeClass("displayNone");
                $("#videoarea").addClass("displayNone");
                $('video').trigger('pause');
            } else {
                $("#videoarea").attr({
                    "src": $(this).attr("movieurl"),
                    "type": $(this).attr("movietype"),
                    "poster": "",
                    "autoplay": "autoplay"
                });
                $("#embedarea").addClass("displayNone");
                $("#videoarea").removeClass("displayNone");
            }
		})
	}
	
	if( $("#videoarea").length > 0 ) {
		$("#videoarea").attr({
			"src": $("#playlist li").eq(0).attr("movieurl"),
			"poster": $("#playlist li").eq(0).attr("moviesposter")
		})
	}
	
	if($("#datePicker").length) {
        $.datetimepicker.setLocale('pl');
        $('#datePicker').datetimepicker({format: "Y-m-d", timepicker: false});
    }
	
	if($("#timePicker").length) {
        $.datetimepicker.setLocale('pl');
        $('#timePicker').datetimepicker({format: "Y-m-d H:i"});
    }

});

$('body').on('click', function (e) {
    $('[data-toggle="popover"]').each(function () {
        //the 'is' for buttons that trigger popups
        //the 'has' for icons within a button that triggers a popup
        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
            $(this).popover('hide');
        }
    });
});

$(document).on("click", ".viewModal", function (e) {
	e.preventDefault();
	e.stopPropagation();
	
	var buttonId = $(this).data('id');
	var $action = $(this).attr('href');
	var $target = $('#modal-container');
	$target.removeAttr('class').addClass(buttonId);

	$target.find(".modalContent")
	   .load($action, 
			function(response, status, xhr){                     
				if ( status == "error" ) {
					var msg = "Sorry but there was an error: ";
					$target.find( ".modalContent" ).html( '<div class="modal-body"><div class="alert alert-danger">'+msg + xhr.status + " " + xhr.statusText+'</div></div>' );
				} 
				$('#flipbook').pageFlip({

					/*time : 2,
					shadow : false,
					slideTime: 0.3,
					autoRun: true,
					autoTime: 4*/

				});
				//$target.find(".modalContent").removeClass('preload');
				setTimeout(function(){ $target.find(".modalContent").removeClass('preload') }, 5000);
			});   
	$('body').addClass('modal-active');
})

//$('#modal-container').click(function(e){
$('.modalClose').click(function(e){
	$('#modal-container').addClass('out');
	$('body').removeClass('modal-active');
});

$(document).on("submit", ".modal-form", function (event) {
	var $form = $(this); //$($(this).attr('data-form'));
	var $target = $($form.attr("data-target"));
	var $table = $($(this).attr('data-table'));
    var $input = $($(this).attr('data-input'));

	$.ajax({
		type: $form.attr("method"),
		url: $form.attr("action"),
		data: $form.serialize(),

		success: function(data, status) {
            if(data.success) {
                $target.modal("hide");
                swal({
                    title: data.title,
                    text: data.alert,
                    type: 'success',
                    confirmButtonText: 'Zamknij'
                });
                
                if(data.action == 'add_note') {
                    if(data.parent == 0)
                        $(".chat-box-history").prepend(data.html);
                    else
                         $(".chat-box-history #comment-"+data.parent).append(data.html);
                }
			} else { 
				$target.find(".modalContent").html(data.html);
                
                var arr = data.errors; var txtError = '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span> </button>';
                $.each(arr, function(index, value) {
                    if (value.length != 0) {
                        txtError += '<strong>'+ value +'</strong><br/>';
                    }
                });
                $target.find(".modal-body").prepend('<div class="alert alert-danger alert-dismissible"  style="margin-bottom:5px;">'+ txtError +'</div>');

			}
		}
	});
    
	event.stopImmediatePropagation();
	event.preventDefault();
	return false;
});

$(document).on("submit", ".modalAjaxForm", function (event) {
	var $form = $(this); //$($(this).attr('data-form'));
	var $target = $($form.attr("data-target"));
	var $table = $($(this).attr('data-table'));
	var $input = $($(this).attr('data-input'));

	$.ajax({
		type: $form.attr("method"),
		url: $form.attr("action"),
		data: $form.serialize(),

		success: function(data, status) {
			if(data.success) {
				$('#modal-container').addClass('out');
				$('body').removeClass('modal-active');
				
				if(data.action == 'termBooking') {
					$("#client-terms").html(data.terms);
				}
		   
				swal({
					title: data.title,
					text: data.alert,
					type: 'success',
					confirmButtonText: 'Zamknij'
				});
			} else { 
				$target.find(".modalContent").html(data.html);
				
				var arr = data.errors; var txtError = '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span> </button>';
				$.each(arr, function(index, value) {
					if (value.length != 0) {
						txtError += '<strong>'+ value +'</strong><br/>';
					}
				});
				$target.find(".modalContent").prepend('<div class="alert alert-danger alert-dismissible"  style="margin-bottom:5px;">'+ txtError +'</div>');

			}
		}
	});
	
	event.stopImmediatePropagation();
	event.preventDefault();
	return false;
});
	
function dataURLToBlob(dataURL) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
        var parts = dataURL.split(',');
        var contentType = parts[0].split(':')[1];
        var raw = parts[1];
        
        return new Blob([raw], {type: contentType});
    }
    else {
        var parts = dataURL.split(BASE64_MARKER);
        var contentType = parts[0].split(':')[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;
        
        var uInt8Array = new Uint8Array(rawLength);
        
        for (var i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        
        return new Blob([uInt8Array], {type: contentType});
    }
}

function readFile(input) {
    //console.log($(input));
    var $path = $(input).data("path"); console.log($path);
    if (input.files && input.files[0]) {
        var reader = new FileReader();          
        reader.onload = function (e) {
            var allFiles = $("#upload")[0].files;
            var file=allFiles[0];
            var data = new FormData();
            //for(var i = 0; i < files.length; i++) data.append('file'+i, files[i]);
             data.append('Files[avatar]', $("#upload")[0].files[0]);
            $.ajax({
                type: 'post',
                url: $path,
                data:  data,
                async: false,
                cache: false,
                contentType: false,
                processData: false,
                success: function (data) {
                    console.log(data);
                    
                    $uploadCrop.croppie('bind', {
                        url: e.target.result
                        //url: data.file
                    });
                    $('.upload-demo').addClass('ready');
                },
                dataType: "json",
            });

        }           
        reader.readAsDataURL(input.files[0]);
    }
    //return false;
}

function relationship() {
    $('.car_insurance').datetimepicker({
        format: 'YYYY-MM-DD',
        inline: false,
        minDate: 'now'
    });
    
    $('.employment_period').datetimepicker({
        format: 'YYYY-MM',
        inline: false,
    });
}
/*
var GalleryBook = (function() {

	var $container = $( '#bcontainer' ),
		$bookBlock = $( '#bb-bookblock' ),
		$items = $bookBlock.children(),
		itemsCount = $items.length,
		current = 0,
		bb = $( '#bb-bookblock' ).bookblock( {
			speed : 800,
			perspective : 2000,
			shadowSides	: 0.8,
			shadowFlip	: 0.4,
			onEndFlip : function(old, page, isLimit) {
				
				current = page;
				// update TOC current
				updateTOC();
				// updateNavigation
				updateNavigation( isLimit );
				// initialize jScrollPane on the content div for the new item
				setJSP( 'init' );
				// destroy jScrollPane on the content div for the old item
				setJSP( 'destroy', old );

			}
		} ),
		$navNext = $( '#bb-nav-next' ),
		$navPrev = $( '#bb-nav-prev' ).hide(),
		$menuItems = $container.find( 'ul.menu-toc > li' ),
		$tblcontents = $( '#tblcontents' ),
		transEndEventNames = {
			'WebkitTransition': 'webkitTransitionEnd',
			'MozTransition': 'transitionend',
			'OTransition': 'oTransitionEnd',
			'msTransition': 'MSTransitionEnd',
			'transition': 'transitionend'
		},
		transEndEventName = transEndEventNames[Modernizr.prefixed('transition')],
		supportTransitions = Modernizr.csstransitions;

	function init() {

		// initialize jScrollPane on the content div of the first item
		setJSP( 'init' );
		initEvents();

	}
	
	function initEvents() {

		// add navigation events
		$navNext.on( 'click', function() {
			bb.next();
			return false;
		} );

		$navPrev.on( 'click', function() {
			bb.prev();
			return false;
		} );
		
		// add swipe events
		$items.on( {
			'swipeleft'		: function( event ) {
				if( $container.data( 'opened' ) ) {
					return false;
				}
				bb.next();
				return false;
			},
			'swiperight'	: function( event ) {
				if( $container.data( 'opened' ) ) {
					return false;
				}
				bb.prev();
				return false;
			}
		} );

		// show table of contents
		$tblcontents.on( 'click', toggleTOC );

		// click a menu item
		$menuItems.on( 'click', function() {

			var $el = $( this ),
				idx = $el.index(),
				jump = function() {
					bb.jump( idx + 1 );
				};
			
			current !== idx ? closeTOC( jump ) : closeTOC();

			return false;
			
		} );

		// reinit jScrollPane on window resize
		$( window ).on( 'debouncedresize', function() {
			// reinitialise jScrollPane on the content div
			setJSP( 'reinit' );
		} );

	}

	function setJSP( action, idx ) {
		
		var idx = idx === undefined ? current : idx,
			$content = $items.eq( idx ).children( 'div.content' ),
			apiJSP = $content.data( 'jsp' );
		
		if( action === 'init' && apiJSP === undefined ) {
			$content.jScrollPane({verticalGutter : 0, hideFocus : true });
		}
		else if( action === 'reinit' && apiJSP !== undefined ) {
			apiJSP.reinitialise();
		}
		else if( action === 'destroy' && apiJSP !== undefined ) {
			apiJSP.destroy();
		}

	}

	function updateTOC() {
		$menuItems.removeClass( 'menu-toc-current' ).eq( current ).addClass( 'menu-toc-current' );
	}

	function updateNavigation( isLastPage ) {
		
		if( current === 0 ) {
			$navNext.show();
			$navPrev.hide();
		}
		else if( isLastPage ) {
			$navNext.hide();
			$navPrev.show();
		}
		else {
			$navNext.show();
			$navPrev.show();
		}

	}

	function toggleTOC() {
		var opened = $container.data( 'opened' );
		opened ? closeTOC() : openTOC();
	}

	function openTOC() {
		$navNext.hide();
		$navPrev.hide();
		$container.addClass( 'slideRight' ).data( 'opened', true );
	}

	function closeTOC( callback ) {

		updateNavigation( current === itemsCount - 1 );
		$container.removeClass( 'slideRight' ).data( 'opened', false );
		if( callback ) {
			if( supportTransitions ) {
				$container.on( transEndEventName, function() {
					$( this ).off( transEndEventName );
					callback.call();
				} );
			}
			else {
				callback.call();
			}
		}

	}

	return { init : init };

})();*/
/*
$(function() {

	GalleryBook.init();

});*/