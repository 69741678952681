$(document).on("click", ".modalConfirm", function (e) {
    e.preventDefault();

    var id = $(this).data('id'); var table = $(this).data('table');
    $('#modalConfirm').data('id', id).data('table', table).modal('show');
    $("#btnYesConfirm").attr("href", $(this).attr("href"));
});

$(document).on("click", ".deleteConfirm", function (e) {
    e.preventDefault();

    var id = $(this).data('id'); var table = $(this).data('table');
	if($(this).hasClass('done')) {
		$target = $("#modal-grid-item");
		$target.find(".modal-title").html('<i class="fa fa-info-circle text--red"></i>Alert');
		//$target.find(".modalContent").addClass('preload');
		/*$("#modal-grid-item").modal("show")
			.find(".modalContent")
			.load('/task/event/showajax/'+calEvent.id);*/
		$target.modal("show").find(".modalContent").html('<div class="modal-body"><div class="alert alert-danger">Zadanie zostało już zamknięte</div></div>');   
	} else {
		$('#modalConfirmDelete').data('id', id).data('table', table).modal('show');
		$("#btnYesDelete").attr("href", $(this).attr("href"));
		if( $(this).attr('data-label') ) {
			$("#btnYesDelete").html($(this).data('label'));
		}
        if( $(this).attr('data-color') ) {
			$("#btnYesDelete").attr('class', 'btn btn-'+$(this).attr('data-color'));
		}
    }
});

$(document).on("click", ".deleteConfirmWithComment", function (e) {
    e.preventDefault();
   
    var id = $(this).data('id'); var table = $(this).data('table');
    $('#modalConfirmDeleteWithComment').data('id', id).data('table', table).modal('show');
    $("#confirm-comment").val('')
    $("#btnYesDeleteWithComment").attr("href", $(this).attr("href"));
    if( $(this).attr('data-label') ) {
        $("#btnYesDeleteWithComment").html($(this).data('label'));
    }
    if( $(this).attr('data-color') ) {
        $("#btnYesDelete").attr('class', 'btn btn-'+$(this).attr('data-color'));
    }
});

$(document).on("click", "#btnYesDelete", function (e) {

    e.stopImmediatePropagation();
    e.preventDefault();
    
    var csrfToken = $('meta[name="csrf-token"]').attr("content");
    
  	var id = $('#modalConfirmDelete').data('id');
    var $row = $(this).data('row')*1;

  	$('#modalConfirmDelete').modal('hide');
    
    $.ajax({
        type: 'post',
        cache: false,
        data: {_csrf : csrfToken},
        dataType: 'json',
        url: $(this).attr('href'),
        beforeSend: function() { 
            $("#validation-form").hide().empty(); 
        },
        success: function(data) {
            $idRow = data.id; 
			if(data.table) 
				var $table = $(data.table);
			else
				var $table = $("#table-data");
                
            if(data.result == true && data.action == 'fileDelete') { 
                var $fileId = data.id;
                $('li#file-'+$fileId).prepend('<div class="file-alert alert alert-success">Dokument został usunięty</div>').delay(1000).fadeOut(600, function(){  $(this).remove(); });
            } else if(data.result == false && data.action == 'fileDelete') { 
                var $fileId = data.id
                $('li#file-'+$fileId).prepend('<div class="file-alert alert alert-danger">'+data.error+'<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span> </button>'+'</div>');
            }
                
            if($table.length == 0)
                $table = $("#table-events");
			if(data.success == false) {
				var txtError = ((data.alert) ? data.alert : 'Dane nie zostały usunięte.')+'<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span> </button>';
                var unix = Math.round(+new Date()/1000);
                
                if($table.length > 0 ) {
                    $table.parent().parent().parent().prepend('<div id="alert-'+unix+'" class="alert alert-danger alert-dismissible"  style="margin-bottom:5px;">'+ txtError +'</div>');
                
                    setTimeout(function(){ $("#alert-"+unix).remove(); }, 5000);
                }
                
                //KAPI.resources.afterSaveFalse(txtError, unix, data);
                //KAPI.tasks.afterSaveFalse(txtError, unix, data);
                
            } else { 
                $table.bootstrapTable('remove', {
                    field: 'id',
                    values: [$idRow]
                });
                //$table.bootstrapTable('refresh');
                var o = {};
                //console.log($('#form-data-search').serializeArray());
                $.each($('#form-data-search').serializeArray(), function() {
                    if (o[this.name] !== undefined) {
                        if (!o[this.name].push) {
                            o[this.name] = [o[this.name]];
                        }
                        o[this.name].push(this.value || '');
                    } else {
                        o[this.name] = this.value || '';
                    }
                });
                
                $table.bootstrapTable('refresh', {
                    url: $('#table-data').data("url"), method: 'get',
                    query: o
                });
				var unix = Math.round(+new Date()/1000);
                var txtError = data.alert+'<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span> </button>';
                
                if( $table.length > 0 ) {
                    $table.parent().parent().parent().prepend('<div id="alert-'+unix+'" class="alert alert-success alert-dismissible"  style="margin-bottom:5px;">'+ txtError +'</div>');
                    setTimeout(function(){ $("#alert-"+unix).remove(); }, 5000);
                }
                if(data.buttons) { 
                    //$('tr[data-index='+data.index+']').find('.edit-btn-group').html(data.buttons);
                    $('#table-data').bootstrapTable('updateRow', {index: data.index, row: { actions:data.buttons   }});
                }
             
                if ( $("#db-event-"+data.id).length > 0 && data.action == 'delete') {
                    $("#db-event-"+data.id).remove();
                    $(".calendar-block").prepend('<div id="alert-'+unix+'" class="alert alert-success alert-dismissible"  style="margin-bottom:5px;">'+ txtError +'</div>');
                }
                
                if(data.structure) { 
                    $('li.dd-item[data-id='+data.id+']').remove();
                }
                
                if(data.action && data.action == 'accept-comment') {
                    $('li#verify-comment-'+data.id).remove();
                    $("#comments-info").html($("#comments-info").text()*1+1);
                }
                
                //KAPI.tasks.afterSaveTrue(txtError, unix, data);
                //KAPI.resources.afterSaveTrue(txtError, unix, data);
                //KAPI.chat.afterSaveTrue(txtError, unix, data);
            }
            
            if ( $("#modal-grid-event").length > 0 ) {
                $("#modal-grid-event").modal("hide");
            }
            
            if ( $("#modal-grid-item").length > 0 ) {
                $("#modal-grid-item").modal("hide");
            }
        },
        error: function(xhr, textStatus, thrownError) {
            alert('Something went to wrong.Please Try again later...');
        }
    });
    
});

$(document).on("click", "#btnYesDeleteWithComment", function (e) {

    e.stopImmediatePropagation();
    e.preventDefault();
    
    var csrfToken = $('meta[name="csrf-token"]').attr("content");
    
  	var id = $('#modalConfirmDeleteWithComment').data('id');
    var $row = $(this).data('row')*1;
    var $comment = $("#confirm-comment").val();
    
    if($comment) {
        $.ajax({
            type: 'post',
            cache: false,
            data: {_csrf : csrfToken, comment: $comment},
            dataType: 'json',
            url: $(this).attr('href'),
           
            success: function(data) {
                $idRow = data.id; 
                if(data.table) 
                    var $table = $(data.table);
                else
                    var $table = $("#table-data");
                    
                if($table.length == 0)
                    $table = $("#table-events");
                if(data.success == false) {
                    var txtError = ((data.alert) ? data.alert : 'Dane nie zostały usunięte.')+'<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span> </button>';
                    var unix = Math.round(+new Date()/1000);
                    $(".field-confirm-comment > .help-block").text(data.alert);                    
                } else { 
                    $('#modalConfirmDeleteWithComment').modal('hide');
                    $(".panel-actions").html('<span class="btn btn-danger">Odrzucony</span>');
                    //$table.bootstrapTable('refresh');
                    var o = {};
                    //console.log($('#form-data-search').serializeArray());
                    $.each($('#form-data-search').serializeArray(), function() {
                        if (o[this.name] !== undefined) {
                            if (!o[this.name].push) {
                                o[this.name] = [o[this.name]];
                            }
                            o[this.name].push(this.value || '');
                        } else {
                            o[this.name] = this.value || '';
                        }
                    });
                    
                    $table.bootstrapTable('refresh', {
                        url: $('#table-data').data("url"), method: 'get',
                        query: o
                    });
                    var unix = Math.round(+new Date()/1000);
                    var txtError = data.alert+'<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span> </button>';
                    
                    if( $table.length > 0 ) {
                        $table.parent().parent().parent().prepend('<div id="alert-'+unix+'" class="alert alert-success alert-dismissible"  style="margin-bottom:5px;">'+ txtError +'</div>');
                        setTimeout(function(){ $("#alert-"+unix).remove(); }, 5000);
                    }
                    if(data.buttons) { 
                        //$('tr[data-index='+data.index+']').find('.edit-btn-group').html(data.buttons);
                        $('#table-data').bootstrapTable('updateRow', {index: data.index, row: { actions:data.buttons   }});
                    }
                 
                    if ( $("#db-event-"+data.id).length > 0 && data.action == 'delete') {
                        $("#db-event-"+data.id).remove();
                        $(".calendar-block").prepend('<div id="alert-'+unix+'" class="alert alert-success alert-dismissible"  style="margin-bottom:5px;">'+ txtError +'</div>');
                    }
                    
                    ///KAPI.tasks.afterSaveTrue(txtError, unix, data);
                    ///KAPI.resources.afterSaveTrue(txtError, unix, data);
                    ///KAPI.chat.afterSaveTrue(txtError, unix, data);
                    
                    if ( $("#modal-grid-event").length > 0 ) {
                        $("#modal-grid-event").modal("hide");
                    }
                    
                    if ( $("#modal-grid-item").length > 0 ) {
                        $("#modal-grid-item").modal("hide");
                    }
                    
                    if(data.action && data.action == 'discard-comment') {
                        $('li#verify-comment-'+data.id).remove();
                    }
                }
                
                
            },
            error: function(xhr, textStatus, thrownError) {
                alert('Something went to wrong.Please Try again later...');
            }
        });
    } else {
        $("#confirm-comment").addClass('alert-danger');
    }
    
});